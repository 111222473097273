var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modSet
    ? _c(
        "div",
        {
          staticClass: "store-mod-set-detail row no-gutters",
          attrs: { id: "store-mod-set-detail" },
        },
        [
          _c("context-title", {
            staticClass: "col-12",
            attrs: { title: _vm.title, passedActions: _vm.titleBarActions },
          }),
          _c("key-val-line", { attrs: { prop: "Enabled" } }, [
            _vm._v(" " + _vm._s(_vm.modSet.enabled ? "Yes" : "No") + " "),
          ]),
          _c("key-val-line", { attrs: { prop: "Name" } }, [
            _vm._v(" " + _vm._s(_vm.modSet.title) + " "),
          ]),
          _c("key-val-line", { attrs: { prop: "Mod Groups" } }, [
            _c(
              "div",
              { staticClass: "row no-gutters" },
              _vm._l(_vm.fixture, function (mg, i) {
                return _c("mod-group", {
                  key: Math.random(),
                  staticClass: "col-12 col-sm-8 col-lg-6",
                  class: { "block-xs--sm-bottom": i < _vm.fixture.length - 1 },
                  attrs: {
                    readOnly: true,
                    active: _vm.isActiveGroup(mg.optionID),
                  },
                  on: { toggleActive: _vm.toggleActive },
                  model: {
                    value: _vm.fixture[i],
                    callback: function ($$v) {
                      _vm.$set(_vm.fixture, i, $$v)
                    },
                    expression: "fixture[i]",
                  },
                })
              }),
              1
            ),
            _c("div", { staticClass: "row" }, [
              _c(
                "a",
                {
                  staticClass:
                    "col-12 store-mod-set-detail__add-group block-xs--xs-top",
                  attrs: { href: "#!" },
                },
                [_vm._v(" Add another Mod Group ")]
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }