<template>
    <div 
        v-if="modSet" 
        id="store-mod-set-detail" 
        class="store-mod-set-detail row no-gutters">
        <context-title 
            :title="title" 
            :passedActions="titleBarActions" 
            class="col-12" />
        <key-val-line prop="Enabled">
            {{ modSet.enabled ? 'Yes' : 'No' }}
        </key-val-line>
        <key-val-line prop="Name">
            {{ modSet.title }}
        </key-val-line>
        <key-val-line prop="Mod Groups">
            <div class="row no-gutters">
                <mod-group
                    v-for="(mg, i) in fixture"
                    :key="Math.random()"
                    :class="{'block-xs--sm-bottom': i < fixture.length - 1}"
                    :readOnly="true"
                    :active="isActiveGroup(mg.optionID)"
                    v-model="fixture[i]"
                    class="col-12 col-sm-8 col-lg-6"
                    @toggleActive="toggleActive" />
            </div>
            <div class="row">
                <a 
                    href="#!" 
                    class="col-12 store-mod-set-detail__add-group block-xs--xs-top">
                    Add another Mod Group
                </a>
            </div>
        </key-val-line>
    </div>
</template>

<script>
import contextTitle from 'components/context_title.vue'
import keyValLine from 'components/key_val_line.vue'
import modGroup from 'components/mods/mod_group.vue'
import { mapGetters } from 'vuex'
export default {
    name: 'StoreModSetDetail',
    data() {
        return {
            activeGroups: ['12345'],
            titleBarActions: [
                {
                    type: 'edit',
                    display: 'Edit',
                    run: () => {
                        this.$router.push({name: 'store-mod-set-edit', params: {set: this.modSet.id}})
                    },
                },
            ],
        }
    },
    computed: {
        modSet() {
            return this.modSets.filter(modSet => modSet.id === Number(this.$route.params.set))[0]
        },
        title() {
            return [
                {to: {name: 'store-mod-index'}, label: 'Mod Set'},
                this.modSet.title,
            ]
        },
        modGroups() {
            return this.allModGroups().filter(modGroup => this.modSet.modGroups.indexOf(modGroup.id) !== -1)
        },
        fixture() {
            return this.modGroups.map(modGroup => {
                return {
                    optionID: modGroup.id,
                    optionGroupName: modGroup.title,
                    inventoryOptions: this.mods().filter(mod => modGroup.id === mod.modGroupId).map(mod => {
                        return {
                            optionDescription: mod.title,
                        }
                    }),
                }
            })
        },
        ...mapGetters({
            modSets: 'modSets',
            allModGroups: 'modGroups',
            mods: 'mods',
        }),
    },
    methods: {
        toggleActive(id) {
            if (this.activeGroups.indexOf(id) === -1) {
                this.activeGroups.push(id)
            } else {
                this.activeGroups.splice(this.activeGroups.indexOf(id), 1)
            }
        },
        isActiveGroup(id) {
            return this.activeGroups.indexOf(`${id}`) !== -1
        },
    },
    components: {
        contextTitle,
        keyValLine,
        modGroup,
    },
}
</script>

<style lang="scss">
    @import '~scss/variables';
    .store-mod-set-detail {
        &__add-group {
            color: $brand_color;
            display: block;
            &:hover {
                color: darken($brand_color, 5%);
            }
        }
    }
</style>
